.footer {
    min-width: 1000px;
    background: #212B36;
}
.footer-content {
    display: flex;
    width: 1000px;
    height: 214px ;
    margin: 0 auto;
    padding: 48px 20px 38px;

}
.footer-item.i1 {
    width: 358px;
}
.footer-item .title {
    margin-bottom: 12px;
    font-size: 16px;
    color: #fff;
    line-height: 26px;
}
.footer-item.i1 .link-wrap{
    float: left;
}
.footer-item .link-wrap {
    width: 152px;
    height: 24px;
    line-height: 24px;
    margin-bottom: 4px;
    font-size: 14px;
}
.footer-item .link-wrap > a{
    color: rgba(255, 255, 255, .6);
}
.footer-item .link-wrap > a:hover{
    color: #fff;
}
.footer-item.i2 {
    width: 358px;
}
.footer-item.i3 {
    flex: 1;
    text-align: right;
}
.footer-item.i3 .qrcode {
    width: 90px;
    height: 90px;
    margin-top: 12px;
}
.copyright {
    
    line-height: 24px;
    text-align: center;
    color: rgba(255, 255, 255, .4);
    background: #121A23;
    font-size: 12px;
}
.copyright > a {
    color: rgba(255, 255, 255, .4);
}
/*.copyright > a:hover {*/
/*    color: #fff;*/
/*}*/
